body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.euiTableHeaderMobile {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 200;
}

.euiBasicTable>div:nth-child(2) {
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 200;
}

.euiTable>thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 200;
}

:focus-visible {
  outline-style: none !important;
}

.logo-admin {
  width: 200px;
}

.main{
  padding-block-start: 48px;
  width:100%;
}

@keyframes shake {
  0% { transform: translateX(0); }
  20% { transform: translateX(-10px); }
  40% { transform: translateX(10px); }
  60% { transform: translateX(-10px); }
  80% { transform: translateX(10px); }
  100% { transform: translateX(0); }
}

.error-message {
  animation: shake 0.5s;
}